/* Post.css */
.post-container {
    padding-top: 5rem !important;
    font-family: 'Cormorant Garamond', serif;
    line-height: 1.6;
    max-width: 800px;
    margin: 0 auto;
    overflow-x: hidden;
  }
  
  .post-container h1 {
    font-weight: 700;
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: #2c2c2c;
  }
  
  .post-container :where(h2, h3, h4, h5, h6) {
    font-weight: 700;
    margin: 1.5em 0 1em;
    color: #363636;
  }
  
  .post-container p {
    font-size: 1.6rem;
    line-height: 1.8;
    margin-bottom: 1.5rem;
    font-weight: 400;
    color: #242424;
  }
  
  .post-container img {
    max-width: 100%;
    height: auto;
    margin: 1.5rem 0;
    border-radius: 4px;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .post-container img:hover {
    transform: scale(1.02);
  }
  
  .post-container a {
    color: #2c5f7a;
    text-decoration: none;
    border-bottom: 1px solid transparent;
    transition: all 0.3s ease;
  }
  
  .post-container a:hover {
    color: #1a3a4d;
    border-bottom-color: currentColor;
  }
  
  .post-container ul,
  .post-container ol {
    margin: 1.5rem 0;
    padding-left: 2rem;
  }
  
  .post-container li {
    margin-bottom: 0.5rem;
  }
  
  .post-container blockquote {
    border-left: 3px solid #2c5f7a;
    margin: 2rem 0;
    padding: 1rem 1.5rem;
    font-style: italic;
    color: #555;
    background-color: #f9f9f9;
  }
  
  .post-container code {
    font-family: 'Courier New', Courier, monospace;
    background-color: #f3f3f3;
    padding: 0.2em 0.4em;
    border-radius: 3px;
  }
  
  .post-container pre {
    background-color: #f8f8f8;
    padding: 1rem;
    overflow-x: auto;
    border-radius: 4px;
    margin: 1.5rem 0;
  }
  
  .post-container table {
    width: 100%;
    margin: 2rem 0;
    border-collapse: collapse;
  }
  
  .post-container th,
  .post-container td {
    padding: 0.75rem;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .post-container th {
    background-color: #f5f5f5;
    font-weight: 600;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .post-container {
      padding: 5rem 1.5rem 2rem !important;
    }
    
    .post-container h1 {
      font-size: 2.5rem;
    }

    .post-container h2 {
        font-size: 2rem;
        }
    
    .post-container p {
      font-size: 1.8rem;
    }
  }
  
  @media (max-width: 480px) {
    .post-container {
      padding: 5rem 1rem 2rem !important;
    }
    
    .post-container h1 {
    font-size: 2.5rem;
    }

    .post-container h2 {
        font-size: 2rem;
        }
    
    .post-container pre {
      font-size: 0.9rem;
    }
  }
  
  /* Estilo para imágenes clickeables */
  .image-modal-trigger {
    cursor: pointer;
    position: relative;
    display: block;
  }
  
  .image-modal-trigger::after {
    content: "🔍 Click para ampliar";
    position: absolute;
    bottom: 10px;
    right: 10px;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 0.3rem 0.5rem;
    border-radius: 3px;
    font-size: 0.8rem;
    font-family: sans-serif;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .image-modal-trigger:hover::after {
    opacity: 1;
  }