/* General spacing for all screens */
.container-fluid {
  padding-left: 15px; /* Ajuste básico para todo el contenido */
  padding-right: 15px;
  max-width: 100%; /* Asegurar que no supere el ancho total en pantallas pequeñas */
  font-family: 'Cormorant Garamond', serif; /* Aplicar la fuente */
  line-height: 30px; /* Altura de línea */
  font-size: 20px; /* Tamaño de fuente */
}

/* Larger screens */
@media (min-width: 768px) {
  .container-fluid {
    padding-left: 30px; /* Espaciado adicional para pantallas medianas en adelante */
    padding-right: 30px;
    max-width: 720px; /* Limita el ancho para pantallas medianas */
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1200px) {
  .container-fluid {
    padding-left: 50px; /* Espaciado generoso para pantallas grandes */
    padding-right: 50px;
    max-width: 1200px; /* Ancho limitado para pantallas grandes */
    margin-left: auto;
    margin-right: auto;
  }
}

/* Sidebar adjustments */
.sidebar {
  margin-top: 0rem;
  border-right: 1px solid #ddd; /* Visual separation for the sidebar */
}

/* Main content spacing */
.p-4 {
  margin-top: 1rem;
  padding-left: 10px;
  padding-right: 10px;
}

.p-4 h2{
  font-weight: 600; /* Peso especificado */
  color: black;
  line-height: 2.5;
}

.p-4 h3{
  font-weight: 500; /* Peso especificado */
  color: black;
  line-height: 2;
  font-size: 1.8rem;
}

.p-4 h4{
  font-weight: 600; /* Peso especificado */
  color: black;
  line-height: 2;
  font-size: 1.7rem;
}

.p-4 p{
  font-weight: 400; /* Peso especificado */
  color: #242424;
  font-size: 1.6rem;
  line-height: 1.8;
}

/* Alinear el título del sidebar a la izquierda */
.sidebar h5 {
  text-align: left; /* Alinea el texto del título */
  padding-left: 15px; /* Añade algo de espacio desde el borde */
  font-weight: 800;
}

/* Botones de capítulos alineados a la izquierda */
.sidebar button {
  text-align: left; /* Asegura que el texto del botón también esté alineado a la izquierda */
}

blockquote {
  margin-left: 2em;
  font-weight: 400;
  border-left: 3px solid #ccc;
  padding-left: 1em;
}
